import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";

class Footer extends Component {
	

	render() {

		const d = new Date();
		let year = d.getFullYear();

		return (
		<React.Fragment>
			<footer className="desktop_footer">
				<div className="container-fluid">
					<div className="content content-full">
					<div className="row">
						<div className="col-md-3">
							<img src={`/assets/img/logos/${localStorage.getItem("storeLogo")}`}
								alt={localStorage.getItem("storeName")} className="footer-logo" />
						</div>

						<div className="col-md-3">
							<div className="footer-nav">
								<NavLink to='/pages/about-us'>About Us</NavLink>
								<NavLink to='/pages/contact-us'>Contact Us</NavLink>
								<NavLink to='/pages/register-store'>Register Store</NavLink>
								<NavLink to='/pages/privacy'>Privacy Policy</NavLink>
								<NavLink to='/pages/terms-of-use'>Terms of use</NavLink>
							</div>
						</div>

						<div className="col-md-3">
							<ul className="footer-list">
								<li className="fl-item ng-star-inserted">
									<div className="text-accent font-weight-bold">Phone</div>
									<a href="tel:020 8571 6669">020 8571 6669</a>
									<a href="tel:074 3810 3810">074 3810 3810</a>
								</li>
								
								<li className="fl-item">
									<div className="text-accent font-weight-bold">Working Days</div>
									<div>Mon - Sun / 9:00AM - 9:00PM</div>
								</li>
							</ul>
						</div>

						<div className="col-md-3">
							<ul className="footer-list">
								<li className="fl-item">
									<div className="text-accent font-weight-bold">Email</div>
									<a href="mailto:info@d2dgrocer.com">info@d2dgrocer.com</a>
								</li>
							</ul>
							{/* <h3 className="footer-title">Download Our App</h3>
							<div>
								<a href="https://play.google.com/store/apps/details?id=com.d2dgrocer.app" target="_blank">
									<img src="/assets/img/playstore.svg" alt="Download D2D App from Google Play" />
								</a>
								<a href="https://apps.apple.com/gb/app/d2d-grocer/id1594405171" target="_blank">
									<img src="/assets/img/applestore.svg" alt="Download D2D App from Apple Store" />
								</a>
							</div> */}

							{this.props.languages && this.props.languages.length > 1 && (
								<div className="mt-4 d-flex align-items-center">
								<div className="mr-2">
									{localStorage.getItem("changeLanguageText")}
								</div>
								<select onChange={this.props.handleOnChange}
									defaultValue={
										localStorage.getItem("userPreferedLanguage")
										? localStorage.getItem("userPreferedLanguage")
										: this.props.languages.filter(
											(lang) => lang.is_default === 1
										)[0].id
									}
									className="form-control language-select"
									>
									{this.props.languages.map((language) => (
									<option value={language.id} key={language.id}>
										{language.language_name}
									</option>
									))}
								</select>
								</div>
							)}
						</div>
					</div>
					</div>
				</div>
				<div className="container-fluid footer-bottomBar">
					Copyright D2DG {year} - All rights reserved.
				</div>
			</footer>
		</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
});

export default withRouter(
	connect(mapStateToProps, {})(Footer)
);
//export default Footer;
