import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { withRouter } from "react-router-dom";

import { WEBSITE_URL } from "../../../configs/website";
import SingleDesktopItem from "../../Mobile/Items/SingleDesktopItem";
import FloatCart from "../../Mobile/FloatCart";

import { getRestaurantAllItems } from "../../../services/items/actions";

class HomeProducts extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			allProducts: [],
			isLoading: false,
			api_status: 0	// 0:nocall, 1:in process, 2: complete, 3:error, 4:complete but no data
		};
	}

	componentDidMount = () => {
		//this.getAllProducts();
		this.getRestaurantAllItems(this.props);
	};

	getAllProducts() {
		let store_slug = JSON.parse(localStorage.getItem("selectedStore")).slug;
		axios.post(WEBSITE_URL+"/public/api/get-all-items-paginated/"+store_slug+"?page=1")
		.then((response) => {
			const resProducts = response.data.items.data;
			this.setState({
				allProducts: resProducts.slice(0, 15),
				nextUrl: response.data.items.next_page_url,
			});
		})
		.catch((error) => {
			console.log("errr-->", error);
		});
	}

	getRestaurantAllItems = (props) => {
		if(props.restaurant_items.length > 0) {
			const default_cat = props.restaurant_items[0];
			const resProducts = default_cat.categories_ids[0].subcategories[0].items;
			this.setState({
				data: props.restaurant_items,
				isLoading: false,
				api_status: 2,

				allProducts: resProducts.slice(0, 15),
			});
		} 
		else{ 
			if(this.state.api_status === 0) {
				this.props.getRestaurantAllItems(this.state.store_id);
				this.setState({ api_status: 1 });
			}
			else if(this.state.api_status === 1) {
				this.setState({ isLoading: false, api_status: 4 });
			}
		}
	};

	render() {

		if(this.state.allProducts.length <= 0) return null;

		return (
		<React.Fragment>

			<div className="row m-20">
				<div className="home_products_heading">
					<h3 className="fancy-span seasonal">Our products</h3>
				</div>
			</div>

			{this.state.allProducts.length > 0 ? (
			<div className="row m-20">
				{this.state.allProducts.map((product, index) => product.is_active == 1 && (
					<div className="col-5r" key={index}>
						<SingleDesktopItem key={index} single_item={product} history={this.props.history} />
					</div>
				) )}
			</div>
			) : (
				<div className="no_products">
					Sorry, we are maintaining the system. We will be back soon
				</div>
			)}

			<div className="row m-20">
				<div className="home_products_heading">
					<h2 className="heading_link" onClick={() => {
						const ss = JSON.parse(localStorage.getItem("selectedStore"));
						this.props.history.push('/AllProducts/'+ss.id+'/'+ss.slug);
					}}>View All</h2>
				</div>
			</div>

			<FloatCart />

		</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	restaurant_items: state.items.restaurant_items,
});

export default withRouter(
	connect(mapStateToProps, {
		getRestaurantAllItems
	})(HomeProducts)
);