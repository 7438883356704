// Use this for Local Development
// export const WEBSITE_URL = "http://127.0.0.1/local";

//Use this for Live
export const WEBSITE_URL = "https://d2dgrocer.com";


//Use this for testing
//export const WEBSITE_URL = "https://production.goodysbasket.com";

// Comment out the above and Uncomment the below line before building the files for Production
// export const WEBSITE_URL = "https://" + window.location.hostname;
