import React, { Component } from "react";

class ItemBadgeRecommend extends Component {
  render() {
    return (
      <React.Fragment>
        <span className="item-badge-collection">
          {this.props.item.is_recommended === 1 ? (
            <span
              className="item-badge"
              style={{
                backgroundColor: localStorage.getItem("recommendedBadgeColor"),
                // bottom: 40,
                // right: 20,
              }}
            >
              {localStorage.getItem("recommendedBadgeText")}
            </span>
          ) : null}
        </span>
      </React.Fragment>
    );
  }
}

export default ItemBadgeRecommend;
