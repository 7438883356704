export const GET_RESTAURANT_INFO = "GET_RESTAURANT_INFO";
export const GET_RESTAURANT_INFO_BY_ID = "GET_RESTAURANT_INFO_BY_ID";
export const GET_RESTAURANT_ITEMS = "GET_RESTAURANT_ITEMS";
export const RESET_ITEMS = "RESET_ITEMS";
export const RESET_INFO = "RESET_INFO";
export const SINGLE_ITEM = "SINGLE_ITEM";
export const RESET_BACKUP = "RESET_BACKUP";

export const SEARCH_ITEM = "SEARCH_ITEM";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const SET_FAVORITE_REST = "SET_FAVORITE_REST";
export const GET_RESTAURANT_INFO_FOR_LOGGED_IN_USER = "GET_RESTAURANT_INFO_FOR_LOGGED_IN_USER";