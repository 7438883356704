import React, { Component } from "react";

class ItemBadge extends Component {
  render() {
    return (
      <div>
        <div style={{}}>
          {this.props.item.is_popular === 1 ? (
            <div
              className="item-badge"
              style={{
                backgroundColor: localStorage.getItem("popularBadgeColor"),
                flexDirection: "row",
                marginLeft: "-5px",
                // paddingHorizontal: 20,
              }}
            >
              {localStorage.getItem("popularBadgeText")}
            </div>
          ) : null}
          {this.props.item.is_new === 1 ? (
            <div
              className="item-badge-new"
              style={{
                backgroundColor: localStorage.getItem("newBadgeColor"),
                flexDirection: "row",
                marginLeft: "-5px",
                // paddingLeft: 30,
                // paddingHorizontal,
                // right: 10,
                top: 4,
              }}
            >
              {localStorage.getItem("newBadgeText")}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ItemBadge;
