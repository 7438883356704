import React, { Component } from "react";

import Desktop from "../../components/Desktop";
import Mobile from "../../components/Mobile";
import { connect } from "react-redux";
import { getSettings } from "../../services/settings/actions";

import { getAllLanguages } from "../../services/languages/actions";


/* var url = 'https://d2dgrocer.com/public/api/get-all-languages';
caches.match(url).then(async function(response) {

	if(response === undefined) response = await fetch(url);
	const date = await response.headers.get('date');

	console.log(date);
});

fetch(url).then(async response => {
	const date = await response.headers.get('date');
	console.log('fetch date', date);

	if(date === null) {
		var response2 = new Response(response.body, {	headers: {response.clone().headers,  'cache-date': 'le das'}	});
	}

	const date2 = await response2.headers.get('date');
	console.log('new date', date2);
}); */



/* if (caches) {
	console.log("CACHES:", caches);
	caches.keys().then(function(names) {
		for (let name of names) {
			if(name === 'd2d-api-cache'){
				console.log("CACHE Name:", name);
				//caches.delete(name);
			}
		}
	});
}

var url = 'https://d2dgrocer.com/public/api/get-all-languages';
caches.match(url).then(function(response) {
	const date = new Date(response.headers.get('date'))
	console.log(date);
}); */

class App extends Component 
{
	componentDidMount() {
		// don't fetch data from server if state has it
		//console.log(this.props.settings);
		//console.log(this.props.languages);
		//if(this.props.settings.length === 0) this.props.getSettings();

		this.props.getSettings();
		this.props.getAllLanguages();
	}

	render() {
		return (
			<React.Fragment>
				{window.innerWidth <= 768 ? (
					<Mobile languages={this.props.languages} />
				) : (
					<Desktop languages={this.props.languages} />
				)} 
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	settings: state.settings.settings,
	user: state.user.user,
	notification_token: state.notification_token.notification_token,
	languages: state.languages.languages,
	//languages: state.languages,
});

export default connect(
	mapStateToProps,
	{ getSettings, getAllLanguages }
)(App);
