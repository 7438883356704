import React, { Component } from "react";

import DelayLink from "../../helpers/delayLink";
import Ink from "react-ink";
import Meta from "../../helpers/meta";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router";
import { connect } from "react-redux";

import { getSettings } from "../../../services/settings/actions";
import { getSingleLanguageData } from "../../../services/languages/actions";
import "./newStyle.css";


class FirstScreen extends Component {

	componentDidMount() {
		/* const saveUserSetAddress = new Promise((resolve) => {
			const userSetAddress = {
				lat: "51.498656",
				lng: "-0.3707026",
				address: "Southall UB2, UK",
				house: null,
				tag: null,

				doorNo: null,
				postcode: null,
				area: null,
			};
			localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
			resolve("Address Saved");
		}); */
		//    saveUserSetAddress.then(() => {
		//      if (localStorage.getItem("fromCart") === "1") {
		//        localStorage.removeItem("fromCart");
		//            this.context.router.history.push("/cart");
		//          } else {
		//                this.context.router.history.push("/");
		//            }
		//        });

	}

	handleOnChange = (event) => {
		// console.log(event.target.value);
		this.props.getSingleLanguageData(event.target.value);
		localStorage.setItem("userPreferedLanguage", event.target.value);
	};

	componentWillReceiveProps(nextProps) {
		const { settings } = this.props;

		if (this.props.languages !== nextProps.languages) {
		if (localStorage.getItem("userPreferedLanguage")) {
			this.props.getSingleLanguageData(
			localStorage.getItem("userPreferedLanguage")
			);
		} else {
			if (nextProps.languages.length) {
			const id = nextProps.languages.filter(
				(lang) => lang.is_default === 1
			)[0].id;
			this.props.getSingleLanguageData(id);
			}
		}
		}
	}

	render() {
		const { user } = this.props;
		if (localStorage.getItem("userSetAddress") !== null) {
			return <Redirect to="/" />;
		}

		return (
		<React.Fragment>
			<Meta
				seotitle={localStorage.getItem("seoMetaTitle")}
				seodescription={localStorage.getItem("seoMetaDescription")}
				ogtype="website"
				ogtitle={localStorage.getItem("seoOgTitle")}
				ogdescription={localStorage.getItem("seoOgDescription")}
				ogurl={window.location.href}
				twittertitle={localStorage.getItem("seoTwitterTitle")}
				twitterdescription={localStorage.getItem("seoTwitterDescription")}
				/>

			<div className="col-12 p-0" id="firstScreenMain" style={{ height: `${window.innerHeight}px` }} >
				
				<div className="block vertical-center-main m-0">
				<div className="block-content vertical-center-inner pt-10">

					<h1 className="welcome-heading mt-10 ">{localStorage.getItem("firstScreenHeading")}</h1>

					<h2 className="welcome-subheading text-center mt-10 mb-10">
						{localStorage.getItem("firstScreenSubHeading")}
					</h2>

					<DelayLink to="/search-location" delay={200}
						className="btn btn-lg btn-setup-location"
						style={{
							backgroundColor: localStorage.getItem("storeColor"),
							position: "relative",
						}}
						>
						{localStorage.getItem("firstScreenSetupLocation")}
						<Ink duration="500" hasTouch="true" />
					</DelayLink>

					{user.success ? (
					<p className="login-block font-w500 mb-0">
						{localStorage.getItem("firstScreenWelcomeMessage")}{" "}
						{user.data.name}
					</p>
					) : (
					<p className="login-block mb-0">
						{localStorage.getItem("firstScreenLoginText")}{" "}
						<NavLink to="/login" style={{ color: localStorage.getItem("storeColor") }}>
							{localStorage.getItem("firstScreenLoginBtn")}
						</NavLink>
					</p>
					)}

					{this.props.languages && this.props.languages.length > 1 && (
					<div className="mt-4 d-flex align-items-center justify-content-center mb-100">
						<div className="mr-2">
						{localStorage.getItem("changeLanguageText")}
						</div>
						<select
						onChange={this.handleOnChange}
						defaultValue={
							localStorage.getItem("userPreferedLanguage")
							? localStorage.getItem("userPreferedLanguage")
							: this.props.languages.filter(
								(lang) => lang.is_default === 1
								)[0].id
						}
						className="form-control language-select"
						>
						{this.props.languages.map((language) => (
							<option value={language.id} key={language.id}>
							{language.language_name}
							</option>
						))}
						</select>
					</div>
					)}
				</div>
				</div>
			</div>
		</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	settings: state.settings.settings,
	user: state.user.user,
	languages: state.languages.languages,
	language: state.languages.language,
});

export default connect(mapStateToProps, { getSettings, getSingleLanguageData })(
	FirstScreen
);
