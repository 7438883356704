import React, { Component } from "react";

import Ink from "react-ink";
import Fade from "react-reveal/Fade";
import LazyLoad from "react-lazyload";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import { WEBSITE_URL } from "../../../../configs/website";
import ItemBadge from "../ItemList/ItemBadge";
import { addProduct, removeProduct } from "../../../../services/cart/actions";
import ItemBadgeRecommend from "../ItemList/ItemBadgeRecommend";


class SingleDesktopItem extends Component {

	state = {
		update: true,
		modalOpen: false,
		count: 0,
	};

	handleClickOpen = () => {
		this.setState({ modalOpen: true });
	};

	handleClose = () => {
		this.setState({ modalOpen: false });
	};

	forceStateUpdate = () => {
		setTimeout(() => {
			this.forceUpdate();
			if (this.state.update) {
				this.setState({ update: false });
			} else {
				this.setState({ update: true });
			}
		}, 100);
	};

	componentDidMount() {}

	componentWillReceiveProps(nextProps) {}

	componentWillUnmount() {
		document.getElementsByTagName("html")[0].classList.remove("page-inactive");
	}

	onProductDetail(product) {
		// disable product detail page for desktop
		this.props.history.push({
			pathname: "/product/"+product.name.replaceAll(' ', '-'),
			state: { product },
		});
	}

	checkProduct = (item) => {
		if(item.old_price == 0) return true;

		let id = item.id;
		const { cartProducts } = this.props;
		for (var i = 0; i < cartProducts.length; i++) {
			if (cartProducts[i].id === id)
				if (cartProducts[i].quantity >= 5) {
					return false;
				} else {
					return true;
				}
			}
		return true;
	};


	render() {
		const { addProduct, removeProduct, cartProducts, single_item } = this.props;

		return (
		<div>
			{single_item.id && (
			<div className="pitem ">

				<span className="hidden">{(single_item.quantity = 1)}</span>
				<div className="mx-10">
					<React.Fragment>

						{single_item.image !== "" && (
						<div className="img_div">
							<LazyLoad>
								<img src={WEBSITE_URL+single_item.image} alt={single_item.name}
								className="pimage" onClick={() => { this.onProductDetail(single_item); }} />

								<div className="pbadge">
									<ItemBadge item={single_item} />
								</div>
							</LazyLoad>
						</div>
						)}

						{single_item.is_recommended === 1 ? (
							<ItemBadgeRecommend item={single_item} />
						) : (
							<div style={{ paddingBottom: 18 }} />
						)}


						<div className="item-info">
						{localStorage.getItem("hidePriceWhenZero") === "true" && single_item.price === "0.00" ? null : (
							<React.Fragment>

							<div className="item-name" title={single_item.name}>
								{single_item.name.length > 30 ? single_item.name.slice(0, 30) + ".." : single_item.name}
							</div>

							<div className="item-weight">
								{parseFloat(single_item.weight)} {single_item.uom}
							</div>

							{single_item.old_price > 0 && (
							<div className="item-price">
								{" "} 
								{localStorage.getItem("currencySymbolAlign") === "left" && localStorage.getItem("currencyFormat")}
								<strike  style={{ color: "black", }}  >
									<span  style={{ textDecoration: "line-through", color: "rgb(53 172 166)", }}> {single_item.old_price} </span>
								</strike>

								{localStorage.getItem("currencySymbolAlign") === "right" && localStorage.getItem("currencyFormat")} 
								&nbsp;&nbsp;&nbsp;
								{localStorage.getItem("currencySymbolAlign") === "left" && localStorage.getItem("currencyFormat")}
								{single_item.price}
								{localStorage.getItem("currencySymbolAlign") === "right" && localStorage.getItem("currencyFormat")}
								
							</div>
							)}

							{single_item.old_price <= 0 && (
							<div className="item-price">
								{" "}
								{localStorage.getItem("currencySymbolAlign") === "left" && localStorage.getItem("currencyFormat")}
								{" "}
								{single_item.price}
								{localStorage.getItem("currencySymbolAlign") === "right" && localStorage.getItem("currencyFormat")}
							</div>
							)}

							</React.Fragment>
						)}
						</div>


						<div className="item-actions">
							{ single_item.is_active == 1 ? (
							<>
							<div className="btn-group btn-group-lg" role="group" aria-label="btnGroupIcons1">
								<button type="button" className="btn btn-success"
									onClick={() => {
										single_item.quantity = 1;
										this.setState({ count: this.state.count != 0 ? this.state.count - 1 : 0, });
										removeProduct(single_item);

										this.forceStateUpdate();
									}}>
									<span className="">-</span>
									<Ink duration="500" />
								</button>

								<button type="button" className="btn btn-outline-success">
								{cartProducts.find( (cp) => cp.id === single_item.id ) !== undefined ?
									(cartProducts.find( (cp) => cp.id === single_item.id ).quantity) : 0
								}
								</button>

								<button type="button" className="btn btn-success"
									onClick={() => {
										if (this.checkProduct(single_item)) { 
											this.setState({ count: this.state.count++, });
											addProduct(single_item); 
										} else this.handleClickOpen();

										this.forceStateUpdate();
									}}
									>
									<span className="">+</span>
									<Ink duration="500" />
								</button>
							</div>

							</>
							) : (
							<div className="not_available">Sold out</div>
							)}
						</div>

						<div style={{ height: 20 }} />
					</React.Fragment>

					<React.Fragment>
						<Dialog
						open={this.state.modalOpen}
						onClose={this.handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						>
						<DialogTitle id="alert-dialog-title">
							Bulk orders
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
							Please contact us if you want to place orders in bulk
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose} color="primary">
								Okay
							</Button>
							<Button onClick={() => this.props.history.push("/pages/contact-us") } color="primary" >
								Contact us
							</Button>
						</DialogActions>
						</Dialog>
					</React.Fragment>
				</div>
			</div>
			)}
			<div className="pb-3" />

		</div>
		);
	}
}

const mapStateToProps = (state) => ({
	cartProducts: state.cart.products,
});

export default connect(mapStateToProps, {
	addProduct,
	removeProduct,
})(SingleDesktopItem);
