import React, { Component } from "react";

import Ink from "react-ink";
import { Link } from "react-router-dom";

import PWAInstallation from "../PWAInstallation";
import "./newStyle.css";
class Nav extends Component {
  static contextTypes = {
    router: () => null,
  };

  render() {
    return (
      <React.Fragment>
        <div className="col-5 mx-auto">
          <div className="">
            <div className="ml-4">
              <div className="input-group">
                {/* monday update */}
                <div
                  className="doorRoad"
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    alignItems: "center",
                    marginRight: 20,
                  }}
                >
                  {this.props.logo &&
                    (this.props.logoLink ? (
                      <Link to="/">
                        <img
                          src="/assets/img/logos/logo_5.png"
                          alt={localStorage.getItem("storeName")}
                          className="store-logo"
                          style={{ height: "30%" }}
                        />
                      </Link>
                    ) : (
                      <img
                        src="/assets/img/logos/logo_5.png"
                        alt={localStorage.getItem("storeName")}
                        className="store-logo"
                        // style={{  }}
                      />
                    ))}
                </div>
                {/* <div className="" style={{ marginLeft: "25%" }}>
                  <PWAInstallation type={"header"} />
                  <button
                    type="submit"
                    className="btn nav-location truncate-text"
                    style={{
                      position: "relative",
                      maxWidth: window.innerWidth - 130,
                    }}
                    onClick={() => {
                      this.props.loggedin
                        ? this.context.router.history.push("/my-addresses")
                        : this.context.router.history.push("/search-location");
                    }}
                  >
                    {localStorage.getItem("userSetAddress") && (
                      <React.Fragment>
                        <div className="row">
                          {JSON.parse(localStorage.getItem("userSetAddress"))
                            .tag !== null ? (
                            <strong className="text-uppercase">
                              {
                                JSON.parse(
                                  localStorage.getItem("userSetAddress")
                                ).tag
                              }
                            </strong>
                          ) : null}

                          {JSON.parse(localStorage.getItem("userSetAddress"))
                            .house !== null ? (
                            <div>
                              {JSON.parse(
                                localStorage.getItem("userSetAddress")
                              ).house.length > 12
                                ? `${JSON.parse(
                                    localStorage.getItem("userSetAddress")
                                  ).house.substring(0, 12)}...`
                                : JSON.parse(
                                    localStorage.getItem("userSetAddress")
                                  ).house}
                            </div>
                          ) : (
                            <div>
                              {JSON.parse(
                                localStorage.getItem("userSetAddress")
                              ).address.length > 18
                                ? `${JSON.parse(
                                    localStorage.getItem("userSetAddress")
                                  ).address.substring(0, 18)}...`
                                : JSON.parse(
                                    localStorage.getItem("userSetAddress")
                                  ).address}
                            </div>
                          )}
                          <div>
                            <i
                              className="si si-arrow-right nav-location-icon "
                              style={{
                                color: localStorage.getItem("storeColor"),
                              }}
                            />
                            <Ink duration="500" />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </button> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Nav;
