import React, { Component } from "react";

import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Tilt from "react-tilt";

class StoreAchievements extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid store_achievements">
                    <div className="row">
                        <Fade left>
                            <div className="col-xl-4">
                                <Tilt className="Tilt" options={{ max: 40, perspective: 650 }}>
                                    <div className="col-xl-12 store-achievement">
                                        <div className="img">
                                            <img key='au1' src={'/assets/img/banner-desktop/icon1.png'} className="" alt="" />
                                        </div>
                                        <h3>Over 1,500 everyday Products</h3>
                                        <p>Shop for snacks, drinks, fresh produce and more from the brands you know and love.</p>
                                    </div>
                                </Tilt>
                            </div>
                        </Fade>
                        <Flip top>
                            <div className="col-xl-4">
                                <Tilt className="Tilt" options={{ max: 40, perspective: 650 }}>
                                    <div className="col-xl-12 store-achievement">
                                        <div className="img">
                                            <img key='au1' src={'/assets/img/banner-desktop/icon2.png'} className="" alt="" />
                                        </div>
                                        <h3>Deliver in minutes</h3>
                                        <p>Shop for snacks, drinks, fresh produce and more from the brands you know and love.</p>
                                    </div>
                                </Tilt>
                            </div>
                        </Flip>
                        <Fade right>
                            <div className="col-xl-4">
                                <Tilt className="Tilt" options={{ max: 40, perspective: 650 }}>
                                    <div className="col-xl-12 store-achievement">
                                        <div className="img">
                                            <img key='au1' src={'/assets/img/banner-desktop/icon3.png'} className="" alt="" />
                                        </div>
                                        <h3>Absolutly no substitutions</h3>
                                        <p>Shop for snacks, drinks, fresh produce and more from the brands you know and love.</p>
                                    </div>
                                </Tilt>
                            </div>
                        </Fade>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default StoreAchievements;
