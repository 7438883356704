import React, { Component } from "react";

import HomeProducts from "../HomeProducts";
import StoreAchievements from "../StoreAchievements";

class V1 extends Component {
	render() {
		return (
			<React.Fragment>

				<div className="desktop-banner">
					<img key='b1' src={'/assets/img/banner-desktop/banner1.png'} className="carousel_img" alt="" />
					<div className="desktop-banner-overley">
						<a href="#download_app" className="button">Download the App now</a>
					</div>
				</div>

				<div className="container-fluid about_us">
					<div className="row">
						<div className="col-xl-6 about_us_img">
							<img key='au1' src={'/assets/img/banner-desktop/about_us.png'} className="" alt="" />
						</div>
						<div className="col-xl-6 about_us_text">
							<h3>About us</h3>
							<p>In 2020, we found that during the pandemic of Covid-19, people were scared to come out of their houses and go shopping in the stores. As well as long queues, there was a very long waiting period (up to 2-3 weeks) for delivery slots in local shops and supermarkets. These slots were often very overpriced compare to the prices that shops and supermarkets meant to be charging so we tried to find a solution for this. We are able to deliver grocery to your doorstep at Cash & Carry (Wholesale) prices and within a very short period. Whatever you need with a minimum spend of £10 we do free delivery for you. We are proud to be here for You.</p>
						</div>
					</div>
				</div>

				<HomeProducts />

				<div id="download_app" className="container-fluid download_app">
					<div className="row">
						<div className="col-xl-6 download_app_text">
							<h3>Download our App</h3>
							<p>and order from your mobile</p>
							<div>
								<a href="https://play.google.com/store/apps/details?id=com.d2dgrocer.app" target="_blank">
									<img src="/assets/img/playstore.svg" alt="Download D2D App from Google Play" />
								</a>
								<a href="https://apps.apple.com/gb/app/d2d-grocer/id1594405171" target="_blank">
									<img src="/assets/img/applestore.svg" alt="Download D2D App from Apple Store" />
								</a>
							</div>
						</div>
						<div className="col-xl-6 download_app_img">
							<img key='au1' src={'/assets/img/banner-desktop/mobile.png'} className="" alt="" />
						</div>
					</div>
				</div>

				<StoreAchievements />
				
			</React.Fragment>
		);
	}
}

export default V1;
