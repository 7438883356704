import React, { Component } from "react";

import FirstScreen from "./FirstScreen";
import Home from "./Home";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

class Mobile extends Component {

    state = {
        showGdpr: false,
        modalOpen: false
    };

    componentDidMount() {
        setTimeout(() => {
            if (document.getElementsByClassName("popup-content")[0]) {
                document.getElementsByClassName("popup-content")[0].style.backgroundColor = "transparent";
            }
        }, 100);

        if (!localStorage.getItem("gdprAccepted")) {
            localStorage.setItem("gdprAccepted", "false");
            if (localStorage.getItem("showGdpr") === "true") {
                this.setState({ showGdpr: true });
            }
        }

        if (localStorage.getItem("showGdpr") === "true" && localStorage.getItem("gdprAccepted") === "false") {
            this.setState({ showGdpr: true });
        }


        const start_date = new Date('December 25, 2021 00:00:00');
        const end_date = new Date('December 27, 2021 00:00:00');
        const current_time = new Date();
        if(start_date.getTime() < current_time.getTime() && end_date.getTime()  > current_time.getTime()){
            this.setState({ modalOpen: true });
        }

    }

    handleGdprClick = () => {
        localStorage.setItem("gdprAccepted", "true");
        this.setState({ showGdpr: false });
    };

    handleClose = () => {
        this.setState({ modalOpen: false });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.showGdpr && (
                    <div className="fixed-gdpr-mobile">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: localStorage.getItem("gdprMessage")
                            }}
                        ></span>
                        <span>
                            <button
                                className="btn btn-sm ml-2"
                                style={{ backgroundColor: localStorage.getItem("storeColor") }}
                                onClick={this.handleGdprClick}
                            >
                                {localStorage.getItem("gdprConfirmButton")}
                            </button>
                        </span>
                    </div>
                )}

                {localStorage.getItem("userSetAddress") != null ? (
                    <Home />
                ) : (
                    <FirstScreen languages={this.props.languages} />
                )}

                <React.Fragment>
                    <Dialog open={this.state.modalOpen} onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                        <DialogTitle id="alert-dialog-title">
                            <div style={{ textAlign: "center", color: "#248e31" }}>Please note</div>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div style={{fontWeight:"bold", fontSize: 15, textAlign: "center", color: "black",}}>
                                    All drivers are busy due to Christmas. Order will be delivered to you on Monday.
                                </div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{justifyContent:'center'}}>
                            <div className="dialog-buttons">
                                <button onClick={this.handleClose}
                                style={{ backgroundColor: "#248e31", borderRadius: 5, color: "white",
                                    borderWidth: 0, padding:"5px 15px", fontSize: 15, fontWeight: "bold"}}>
                                Got it
                                </button>
                            </div>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
                
            </React.Fragment>
        );
    }
}

export default Mobile;
