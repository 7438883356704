import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink, Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";

import FirstScreen from "../../Mobile/FirstScreen";
import {clearSearch, searchRestaurants} from "../../../services/searchRestaurants/actions";

class Header extends Component {

	state = {
		modalOpen: false,
		searchTerm: ''
	};

	componentDidMount() {

		const userSetAddress = {
			lat: "51.498656",
			lng: "-0.3707026",
			address: "Southall UB2, UK",
			house: null,
			tag: null,

			doorNo: null,
			postcode: null,
			area: null,
		};
		localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
	}

	handleClickOpen = () => {
		this.setState({ modalOpen: true });
	};

	handleClose = () => {
		this.setState({ modalOpen: false });
	};

	handle_shop_now = (event) => {
		event.preventDefault();
		/* if(localStorage.getItem("userSetAddress") === null || 
			localStorage.getItem("userSetAddress").includes("Southall UB2, UK")) this.setState({ modalOpen: true });
		else  this.props.history.push("/AllProducts/"); */

		let ss = JSON.parse(localStorage.getItem("selectedStore"));
		this.props.history.push('/AllProducts/'+ss.id+'/'+ss.slug);
	}

	handleSearchInputChange = (e) => {
		let query = e.target.value;
		this.setState({ searchTerm: query });

		if (query.length >= 3) {
			this.props.searchRestaurants(query);
		}
	};

	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.props.history.push("/explore");
		}
	}

	render() {
		return (
		<React.Fragment>
			<div className="container-fluid desktop_nav_bar">

				<div className="row">
					<div className="col-md-12 d-flex desktop_topNavBar">
						<div className="desktop_topNavBar-left">
							<NavLink to='/login' className="rightBorder">Login</NavLink>
							<NavLink to='/register'>Register</NavLink>
						</div>
						<div className="desktop_topNavBar-right">
								Follow us &nbsp;&nbsp;&nbsp;&nbsp;
								{localStorage.getItem("desktopSocialFacebookLink") !== "null" && (
									<a href={localStorage.getItem("desktopSocialFacebookLink")}
										className="btn btn-sm" target="_blank" rel="noopener noreferrer">
										<i className="fa fa-fw fa-facebook" />
									</a>
								)}

								{localStorage.getItem("desktopSocialGoogleLink") !== "null" && (
									<a href={localStorage.getItem("desktopSocialGoogleLink")}
										className="btn btn-sm" target="_blank" rel="noopener noreferrer">
										<i className="fa fa-fw fa-google-plus" />
									</a>
								)}

								{localStorage.getItem("desktopSocialYoutubeLink") !== "null" && (
									<a href={localStorage.getItem("desktopSocialYoutubeLink")}
										className="btn btn-sm" target="_blank" rel="noopener noreferrer">
										<i className="fa fa-fw fa-youtube-play" />
									</a>
								)}

								{localStorage.getItem("desktopSocialInstagramLink") !== "null" && (
									<a href={localStorage.getItem("desktopSocialInstagramLink")}
										className="btn btn-sm" target="_blank" rel="noopener noreferrer">
										<i className="fa fa-fw fa-instagram" />
									</a>
								)}
							<NavLink to='/cart'><i className="si si-basket"></i> My Order</NavLink>
						</div>
					</div>
				</div>

				<div className="row">
				<div className="col-md-12 d-flex desktop_nav">

					<div className="desktop_nav-left">
						<div className="desktop_nav-logo" onClick={() => this.props.history.push("/")}>
							<img src={`/assets/img/logos/${localStorage.getItem("storeLogo")}`} alt={localStorage.getItem("storeName")} />
						</div>
					</div>

					<div className="desktop_nav-search d-none d-lg-block">
						<div className="input-group" ref="searchGroup" onClick={this.inputFocus} >
							<input type="text" className="form-control items-search-box" placeholder={localStorage.getItem("itemSearchPlaceholder")}
							onFocus={() => { if(this.props.location.pathname != "/explore") this.props.history.push("/explore"); }}
							onKeyUp={this.handleKeyPress}
							onChange={this.handleSearchInputChange} />

							<div className="input-group-append" onClick={() => {
									this.props.history.push({pathname:"/explore", state:{searchTerm : this.state.searchTerm} });
								}}>
								<span className="input-group-text items-search-box-icon">
									<i className="si si-magnifier" />
								</span>
							</div>
						</div>
					</div>

					<div className="desktop_nav-right">
						<NavLink to='/' exact>Home</NavLink>
						<NavLink to='/AllProducts' onClick={ this.handle_shop_now }>Shop Now</NavLink>
						<NavLink to='/pages/register-store'>Join Us</NavLink>
						<NavLink to='/my-account'>Account</NavLink>
					</div>

				</div>
				</div>
			</div>

			<Dialog
				open={this.state.modalOpen}
				onClose={this.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				>
					<iframe title="appIframe" className="desktop_iframe" src={window.location} />
					{/* <FirstScreen /> */}
			</Dialog>
		</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user.user,
	locations: state.locations.locations,
});

export default withRouter(
	connect(mapStateToProps, {searchRestaurants, clearSearch})(Header)
);

//export default Header;
