import { BrowserRouter, Route, Switch } from "react-router-dom";

import App from "./components/App";
import Loadable from "react-loadable";
import Loading from "./components/helpers/loading";
import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import withTracker from "./withTracker";
import Footer from "./components/Desktop/Footer";
import Header from "./components/Desktop/Header";

// import NotFound from "./components/NotFound";
const NotFound = Loadable({
  loader: () => import("./components/NotFound"),
  loading: () => <Loading />,
});

const ModalRoute = Loadable({
  loader: () => import("./components/Desktop/ModalRoute"),
  loading: () => <Loading />,
});

// import Location from "./components/Mobile/Location";
const Location = Loadable({
  loader: () => import("./components/Mobile/Location"),
  loading: () => <Loading />,
});

// import Items from "./components/Mobile/Items";
// const Items = Loadable({
// 	loader: () => import("./components/Mobile/Items"),
// 	loading: () => <Loading />,
// });

// import Login from "./components/Mobile/Auth/Login";
const Login = Loadable({
  loader: () => import("./components/Mobile/Auth"),
  loading: () => <Loading />,
});

// import Register from "./components/Mobile/Auth/Register";
const Register = Loadable({
  loader: () => import("./components/Mobile/Auth/Register"),
  loading: () => <Loading />,
});

// import CartPage from "./components/Mobile/Cart";
const CartPage = Loadable({
  loader: () => import("./components/Mobile/Cart"),
  loading: () => <Loading />,
});

// import Account from "./components/Mobile/Account";
const Account = Loadable({
  loader: () => import("./components/Mobile/Account"),
  loading: () => <Loading />,
});

// import Explore from "./components/Mobile/Explore";
const Explore = Loadable({
  loader: () => import("./components/Mobile/Explore"),
  loading: () => <Loading />,
});

// import Addresses from "./components/Mobile/Account/Addresses";
const Addresses = Loadable({
  loader: () => import("./components/Mobile/Account/Addresses"),
  loading: () => <Loading />,
});

// import Checkout from "./components/Mobile/Checkout";
const Checkout = Loadable({
  loader: () => import("./components/Mobile/Checkout"),
  loading: () => <Loading />,
});


// import RunningOrder from "./components/Mobile/RunningOrder";
const RunningOrder = Loadable({
  loader: () => import("./components/Mobile/RunningOrder"),
  loading: () => <Loading />,
});

// import Orders from "./components/Mobile/Account/Orders";
const Orders = Loadable({
  loader: () => import("./components/Mobile/Account/Orders"),
  loading: () => <Loading />,
});

// import WalletPage from "./components/Mobile/Account/Wallet";
const WalletPage = Loadable({
  loader: () => import("./components/Mobile/Account/Wallet"),
  loading: () => <Loading />,
});
/* Delivery */
// import Delivery from "./components/Delivery";
const Delivery = Loadable({
  loader: () => import("./components/Delivery"),
  loading: () => <Loading />,
});

// import DeliveryLogin from "./components/Delivery/Login";
const DeliveryLogin = Loadable({
  loader: () => import("./components/Delivery/Login"),
  loading: () => <Loading />,
});

// import DeliveryOrders from "./components/Delivery/Orders";
const DeliveryOrders = Loadable({
  loader: () => import("./components/Delivery/Orders"),
  loading: () => <Loading />,
});

const ViewOrder = Loadable({
  loader: () => import("./components/Delivery/ViewOrder"),
  loading: () => <Loading />,
});

const GeoLocationPage = Loadable({
  loader: () => import("./components/Mobile/GeoLocationPage"),
  loading: () => <Loading />,
});

const StoreDetail = Loadable({
  loader: () => import("./components/Mobile/Home/StoreDetail"),
  loading: () => <Loading />,
});

const SingleDetailItem = Loadable({
  loader: () => import("./components/Mobile/Items/SingleDetailItem"),
  loading: () => <Loading />,
});

const SinglePage = Loadable({
  loader: () => import("./components/SinglePage"),
  loading: () => <Loading />,
});

const ForgotPassword = Loadable({
  loader: () => import("./components/Mobile/Auth/ForgotPassword"),
  loading: () => <Loading />,
});

const RestaurantListOnCategory = Loadable({
  loader: () => import("./components/Mobile/Home/RestaurantListOnCategory"),
  loading: () => <Loading />,
});


const RatingAndReview = Loadable({
  loader: () => import("./components/Mobile/Account/Orders/RatingAndReview"),
  loading: () => <Loading />,
});

const Alerts = Loadable({
  loader: () => import("./components/Mobile/Alerts"),
  loading: () => <Loading />,
});

const FavoriteRestaurantList = Loadable({
  loader: () => import("./components/Mobile/Home/FavoriteRestaurants"),
  loading: () => <Loading />,
});

const SubCategoryList = Loadable({
  loader: () => import("./components/Mobile/Home/SubCategories"),
  loading: () => <Loading />,
});
/* const AllProducts = Loadable({
  loader: () => import("./components/Mobile/Home/AllProducts"),
  loading: () => <Loading />,
}); */
const AllProducts = Loadable({
  loader: () => import("./components/Desktop/AllProducts"),
  loading: () => <Loading />,
});

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

localStorage.setItem("selectedStore", JSON.stringify({slug:'d2d-tzinikbjpggwiek', id:'1'}));
localStorage.setItem("userPreferredSelection", "DELIVERY");
localStorage.setItem("userSelected", "DELIVERY");


ReactDOM.render(
	<Root>
	<BrowserRouter>
		<React.Fragment>
			<Route component={ScrollToTop} />
			{window.innerWidth > 768 && (<Header />)}

			<Switch>
				{/* <Route exact strict  path="/:url*" render={props => <Redirect to={`${props.location.pathname}/`} />} /> */}
				<Route exact path={"/"} component={withTracker(App)} />

				{/* Test Routes*/}
				{/* <Route exact path={"/saurabh/test"} component={TestComponent} /> */}

				<Route exact path={"/search-location"} component={withTracker(Location)} />
				<Route exact path={"/mr/search-location"} component={withTracker(ModalRoute)} />
				<Route exact path={"/my-location"} component={withTracker(GeoLocationPage)} />
				<Route exact path={"/categories/stores"} component={withTracker(RestaurantListOnCategory)} />
        
				<Route exact path={"/AllProducts"} component={withTracker(AllProducts)} />
				<Route exact path={"/AllProducts/:store_id/:store_slug"} component={withTracker(AllProducts)} />

				<Route exact path={"/store/:restaurant"} component={withTracker(StoreDetail)} />
        		<Route exact path={"/:store/category/:category"} component={withTracker(SubCategoryList)} />
				<Route exact path={"/product/:name"} component={withTracker(SingleDetailItem)} />

				<Route exact path={"/explore"} component={withTracker(Explore)} />
				<Route exact path={"/login"} component={withTracker(Login)} />
				<Route exact path={"/login/forgot-password"} component={withTracker(ForgotPassword)} />
				<Route exact path={"/register"} component={withTracker(Register)} />

				<Route exact path={"/my-account"} component={withTracker(Account)} />
				<Route exact path={"/alerts"} component={withTracker(Alerts)} />
				<Route exact path={"/my-addresses"} component={withTracker(Addresses)} />
				<Route exact path={"/my-wallet"} component={withTracker(WalletPage)} />
				<Route exact path={"/my-orders"} component={withTracker(Orders)} />
				<Route exact path={"/rate-and-review/:id"} component={withTracker(RatingAndReview)} />

				<Route exact path={"/checkout"} component={withTracker(Checkout)} />
				<Route exact path={"/running-order/:unique_order_id"} component={withTracker(RunningOrder)} />

				<Route exact path={"/cart"} component={withTracker(CartPage)} />

				<Route exact path={"/pages/:slug"} component={withTracker(SinglePage)} />
				<Route exact path={"/my-favorite-stores"} component={withTracker(FavoriteRestaurantList)} />

				{/* Delivery Routes */}
				<Route exact path={"/delivery"} component={Delivery} />
				<Route exact path={"/delivery/login"} component={DeliveryLogin} />
				<Route exact path={"/delivery/orders"} component={DeliveryOrders} />
				<Route exact path={"/delivery/orders/:unique_order_id"} component={ViewOrder} />
				<Route exact path={"/delivery/completed-orders"} component={Delivery} />
				
				{/* Common Routes */}
				<Route component={NotFound} />
			</Switch>
			

			{window.innerWidth > 768 && (<Footer />)}
		</React.Fragment>
	</BrowserRouter>
	</Root>,
	document.getElementById("root")
);
